import { onMounted, onUnmounted, ref } from 'vue';

export type ScreenSizeType = 'desktop' | 'mobile' | 'tablet';

export function useDetectSize() {
  const size = ref<ScreenSizeType>('desktop');

  const update = () => {
    if (!window) return;
    if (window.innerWidth < 640) size.value = 'mobile';
    else if (window.innerWidth < 1024) size.value = 'tablet';
    else size.value = 'desktop';
  };

  onMounted(() => {
    update();
    window.addEventListener('resize', update);
  });

  onUnmounted(() => window.removeEventListener('resize', update));

  return size;
}
